import React, { useEffect, useState, useRef } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import axios from "./interceptor";
import urls from "./endpoint";
import { Helmet } from 'react-helmet';
import 'animate.css';
import "./Annadanam.css";
import Banner from "./assets/img/banner-annadan.jpg";
import annadan from "./assets/img/annadan.jpg";
import prabhupad from "./assets/img/prabhupad.jpg";
import krishna from "./assets/img/krishna.avif";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import krishnachanting from "./assets/gallery/janamshtami/chanting.mp3";
import audioOn from "./assets/gallery/janamshtami/audio-on.png";
import audioOff from "././assets/gallery/janamshtami/audio-off.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
const Annadan = () => {
  const [devoteeData, setDevoteeData] = useState({});
  const [donorData, setDonorData] = useState({
    donorName: "",
    mobileNumber: "",
    emailId: "",
    dob: "",
    address: "",
   
  });
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);

  const [sevasData, setSevasData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedSevaLabel, setSelectedSevaLabel] = useState("");
  const [errors, setErrors] = useState({});
  const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCheckedSevas, setIsCheckedSevas] = useState({});
  const [buttonClickedSevas, setButtonClickedSevas] = useState({});
  const [amountSevas, setAmountSevas] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const { vid } = useParams();
  const [otherAmounts, setOtherAmounts] = useState({});
  const [loading, setLoading] = useState(false);
  // const scrollToForm = () => {
  //   formRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  const [playInLoop, setPlayInLoop] = useState(true);

  const audioRef = useRef(new Audio(krishnachanting));

  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
      audio.play();
    };

    audio.addEventListener("ended", handleEnded, false);

    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);
  

  const playPause = () => {
    const audio = audioRef.current;
    const wasPlaying = isAudioPlaying;

    setIsAudioPlaying(!wasPlaying);

    if (wasPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDonate = () => {
    const newErrors = {};
    let hasError = false;

    setShowError(true);
    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
   
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    // if (!donorData.dob) {
    //   newErrors.dob = "Date of Birth is required";
    //   hasError = true;
    // }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(sevasData, "sevasData from ");
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.donorName,
      devotee_mobile_no: donorData.mobileNumber,
      devotee_email_id: donorData.emailId,
      devotee_dob: '',
      devotee_address: donorData.address,
      sevas: sevasData,
    
      vid: vid,
      festival_name: "Annadan",
    }));
  }, [donorData, sevasData]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    try {
      console.log(devoteeData, "data");
      const response = await axios.post(urls.create_donation, devoteeData);
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      alert("Error", "Failed to create donation. Please try again.");
      throw error;
    }
    // const response = await axios.post(urls.create_donation, devoteeData);

    // return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    if (paymentStatus === "paid") {
      handleShow();
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          handleClose();
          handleShowSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDonateNow = () => {
    const selectedSevas = Object.keys(isCheckedSevas).filter(
      (seva) => isCheckedSevas[seva]
    );

    setSelectedSevaLabel(selectedSevas.join(" & "));
    const newErrors = {};
    let hasError = false;

    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
   
   
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    console.log("hi from donate now");
    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        console.log(res, "from 196");
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();

        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedSevaLabel,
              image: { iskconabidslogo },
              order_id: res.data.data.order_id,
              handler: function (response) {
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.donorName, //your customer's name
                email: donorData.emailId,
                contact: donorData.mobileNumber, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const mobileChangeHandler = (value, country) => {
    console.log(value, country);

    const countryCode = country.dialCode;
    console.log(countryCode, value);

    setDonorData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: null,
    }));
  };
  const changeHandler = (e) => {
    console.log(e);
    setDonorData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };
  const handleOtherAmountChange = (sevaName) => (event) => {
    const amount = event.target.value.trim();
    console.log(amount, "amount from otheramount");
    const isNumeric = new RegExp("^[0-9]+$").test(amount);
    if (amount.length > 0 && isNumeric) {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: amount }));

      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount((prevAmount) => {
          console.log(prevAmount, "prev amount for selected amount");
          // return prevAmount - prevAmountForSeva + Number(amount);
          return amount;
        });
        return { ...prev, [sevaName]: amount };
      });

      setSevasData(() => {
        return [{ name: sevaName, amount: amount }];
      });

      setErrorMsg("");
    } else if (amount === "") {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount((prevAmount) => prevAmount - prevAmountForSeva);
        const newAmountSevas = { ...prev };
        delete newAmountSevas[sevaName];
        return newAmountSevas;
      });

      setSevasData(() => {
        return [];
      });

      setErrorMsg("");
    } else {
      alert("Please Enter a Valid Amount");
    }
  };

  const handleButtonClick = (sevaName, buttonId, amount) => {
    const amountStr = String(amount);

    setButtonClickedSevas((prev) => {
      const prevButtonId = prev[sevaName];
      const isSameButtonClicked = prevButtonId === buttonId;

      if (isSameButtonClicked) {
        setAmountSevas((prevAmountSevas) => {
          const newAmountSevas = { ...prevAmountSevas };
          const amountToSubtract = Number(newAmountSevas[sevaName] || 0);

          setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);

          delete newAmountSevas[sevaName];
          return newAmountSevas;
        });

        setSevasData((prevData) => {
          return prevData.filter((data) => data.name !== sevaName);
        });

        setIsCheckedSevas((prev) => ({ ...prev, [sevaName]: false }));
        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return {};
      } else {
        setAmountSevas((prevAmountSevas) => {
          const prevSevaName = Object.keys(prevAmountSevas).find(
            (key) => prevAmountSevas[key]
          );

          setSelectedAmount((prevAmount) => {
            const prevAmountForSeva = Number(
              prevAmountSevas[prevSevaName] || 0
            );
            const amountToAdd = Number(amountStr);
            return prevAmount - prevAmountForSeva + amountToAdd;
          });

          return { [sevaName]: amountStr };
        });

        setSevasData(() => {
          return [
            {
              name: sevaName,
              amount: amountStr,
            },
          ];
        });

        setIsCheckedSevas((prev) => {
          const newChecked = Object.keys(prev).reduce((acc, key) => {
            if (key !== sevaName) acc[key] = false;
            return acc;
          }, {});
          return { ...newChecked, [sevaName]: true };
        });

        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return { [sevaName]: buttonId };
      }
    });
  };

  return (
    <div>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
    {/* Your app content here */}
  
    <div>
    <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-9 col-5 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-3 col-7  text-left ">
              <button
                className="btn btn-h-donate "
                onClick={playPause}
                style={{ borderRadius: "20px" }}
              >
                {isAudioPlaying ? (
                  <>
                    <marquee width="60%" direction="right" height="18px">
                      Hare Krishna Mantra
                    </marquee>
                    <img
                      src={audioOn}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Pause"
                    />
                  </>
                ) : (
                  <>
                    Hare Krishna Mantra
                    <img
                      src={audioOff}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Play"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
<section className="banner-section">
  <div className="banner-image animate__animated animate__fadeIn">
    <img src={Banner} alt="Banner" className="banner-img" />
  </div>
</section>
<div></div>
<br/>
<div className="container">
<div className="card">
      <h4>Anna Daan Seva</h4>
     
      <p>
        Anna daan is the act of donating food to the needy. In Vedic culture,
        it is considered one of the most pious and virtuous acts. Anna Daan is
        considered to be Mahadaan. It is believed that offering food to others
        is equivalent to offering it to God Himself.
        <br />
        The act of Anna Daan is also considered a form of seva, or selfless
        service, which helps one develop love and compassion towards all living
        beings.
      </p>
    </div>
    </div>
    <section className="signup-container" style={{ marginTop: "10%" }}>
  {/* Left Section */}
  <div className="left-container">
  <h1>Support Annadan Seva</h1>
    
     
  
    <div className="puppy">
      <img
        src="https://iskconmedchal.org/wp-content/uploads/2019/11/akshaya-patra-hyderabad.jpg"
        alt="Annadan Illustration"
      />
    </div>
    <div></div>
    <br/>
    <div className="puppy">
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTLuFFeR5cIb_m5kOzLW5vW-u1eCbQvnenbXEojJLJTPb-Jypp3j_uM5ZoaZFjoE9awuU&usqp=CAU"
        alt="Annadan Illustration"
      />
    </div>
    <div></div>
    <br/>
    <div className="puppy">
      <img
        src={annadan}
        alt="Annadan Illustration"
      />
    </div>
  </div>

  {/* Right Section */}
  {/* Right Section */}
  <div className="right-container">
 
  <div>
  
  <header>
  <header>
    
  </header>
    <h1>Offer a seva</h1>
    <p>
      Your donation helps feed the hungry and serves as a selfless act of love
      and compassion.
    </p>
  </header>
    <div className="set">
      <div className="pets-name">
        <label htmlFor="seva-amount">Select Seva Amount</label>
        <div className="amount-button-container d-flex flex-wrap justify-content-between">
  <div className="button-label-container text-center">
    <button
      className={`checkbox-btn ${
        buttonClickedSevas["Annadanam Seva"] === "button1" ? "active" : ""
      }`}
      onClick={() => handleButtonClick("Annadanam Seva", "button1", 1001)}
    >
      Rs.1,001
    </button>
    <div className="amount-label">100 visitors</div>
  </div>

  <div className="button-label-container">
    <button
      className={`checkbox-btn ${
        buttonClickedSevas["Annadanam Seva"] === "button2" ? "active" : ""
      }`}
      onClick={() => handleButtonClick("Annadanam Seva", "button2", 2501)}
    >
      Rs.2,501
    </button>
    <div className="amount-label">2501 visitors</div>
  </div>

  <div className="button-label-container">
    <button
      className={`checkbox-btn ${
        buttonClickedSevas["Annadanam Seva"] === "button3" ? "active" : ""
      }`}
      onClick={() => handleButtonClick("Annadanam Seva", "button3", 5001)}
    >
      Rs.5,001
    </button>
    <div className="amount-label">501 visitors</div>
  </div>

  <div className="pets-name">
    <label htmlFor="other-amount">Other Amount</label>
    <input
      type="text"
      id="other-amount"
      name="otherAmount"
      value={otherAmounts["Annadanam Seva"] || ""}
      placeholder="Enter Amount"
      onChange={handleOtherAmountChange("Annadanam Seva")}
    />
  </div>
</div>

      </div>
    </div>

    <div className="set">
      <div className="pets-name">
        <label htmlFor="donorName">Enter Name</label>
        <input
          type="text"
          id="donorName"
          name="donorName"
          value={donorData.donorName}
          placeholder="Enter Donor Name"
          onChange={(e) => changeHandler(e)}
        />
      </div>
      <div className="pets-name">
        <label htmlFor="emailID">Enter Email</label>
        <input
          type="text"
          id="emailID"
          name="emailId"
          value={donorData.emailId}
          placeholder="Enter Email ID"
          onChange={(e) => changeHandler(e)}
        />
      </div>
    </div>

    <div className="set">
      <div className="pets-name">
        <label htmlFor="phone">Enter Mobile Number</label>
        <PhoneInput
          country={"in"}
          value={donorData.mobileNumber}
          onChange={mobileChangeHandler}
          placeholder="Enter Mobile Number"
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
        />
      </div>
      <div className="pets-name">
        <label htmlFor="dob">Date of Birth</label>
        <input
          type="date"
          id="dob"
          name="dob"
          value={donorData.dob}
          onChange={(e) => changeHandler(e)}
        />
      </div>
    </div>

    <div className="set">
      <div className="pets-name">
        <label htmlFor="address">Enter Address</label>
        <textarea
          id="address"
          name="address"
          value={donorData.address}
          placeholder="Enter Address"
          onChange={(e) => changeHandler(e)}
        />
      </div>
    </div>

    {/* Terms and Conditions */}
    <footer>
      <p>
        By continuing, you agree to our{" "}
        <a
          href="https://iskconhyderabad.com/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </p>
      <div className="btn-group">
        {selectedAmount !== 0 ? (
          <button className="btn" onClick={handleDonateNow}>
            Donate Rs-{selectedAmount}
          </button>
        ) : (
          <button className="btn" onClick={handleDonate}>
            Donate Now
          </button>
        )}
      </div>
    </footer>
  </div>
</div>

</section>



<div class="ag-format-container">
  <div class="ag-courses_box">

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>
        <div class="ag-courses-item_title">What is Anna Daan?</div>
        <div class="ag-courses-item_date-box">
          <p>
            "Annaṁ bahu kurvīta śraddhayā yasya dehinaḥ | tasya siddhiṁ paramāṁ gacchet"
            (Bṛhadāraṇyaka Upaniṣad, 1.3.24)
          </p>
          <p>
            Anna daan is the act of donating food to the needy. In Vedic culture, it is considered one of the most pious and virtuous acts. Anna Daan is Mahadaan.
          </p>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>
        <div class="ag-courses-item_title">
          What is the significance of performing Anna Daan?
        </div>
        <div class="ag-courses-item_date-box">
          <p>
            "Annaṁ dātum mahat pāpaṁ nāśayati | sarvaṁ pāpaṁ nāśayati" (Mahābhārata, Anuśāsana Parva, 66.22)
          </p>
          <p>
            Meaning: "Giving food is a great act that destroys all sins. It destroys all sinful reactions."
          </p>
        </div>
      </a>
    </div>

    <div class="ag-courses_item">
      <a href="#" class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>
        <div class="ag-courses-item_title">
          Is Anna Daan a form of worship?
        </div>
        <div class="ag-courses-item_date-box">
          <p>
            "Annaṁ brahma, annaṁ param | annaṁ dātum paramaṁ tapaḥ"
            (Chāndogya Upaniṣad, 7.23.1)
          </p>
          <p>
            Meaning: "Food is Brahman (the Supreme), food is the highest. Giving food is the highest austerity."
          </p>
        </div>
      </a>
    </div>

  </div>
</div>
<h1 style={{ color: 'black', marginBottom: '20px' ,justifyContent: 'center',textAlign:'center'}}>Gallery</h1>
    <div style={{ display: "grid", placeItems: "center", height: "60vh", margin: 0 }}>
      <section className="gallery">
        <span style={{ "--i": 1 }}>
          <img src="http://iskconhyderabad.com/wp-content/uploads/01-2-scaled.jpg" alt="Ekantha" />
        </span>
        <span style={{ "--i": 2 }}>
          <img src="http://iskconhyderabad.com/wp-content/uploads/04-1-scaled.jpg" alt="" />
        </span>
        <span style={{ "--i": 3 }}>
          <img src="http://iskconhyderabad.com/wp-content/uploads/DSC05369-scaled.jpg" alt="" />
        </span>
        <span style={{ "--i": 4 }}>
          <img src="http://iskconhyderabad.com/wp-content/uploads/02-1-scaled.jpg" alt="" />
        </span>
        <span style={{ "--i": 5 }}>
          <img
            src="http://iskconhyderabad.com/wp-content/uploads/WhatsApp-Image-2024-08-05-at-2.35.35-PM.jpeg"
            alt=""
          />
        </span>
        <span style={{ "--i": 6 }}>
          <img src="http://iskconhyderabad.com/wp-content/uploads/09-1-scaled.jpg" alt="" />
        </span>
        <span style={{ "--i": 7 }}>
          <img src={krishna}alt="Krishna" />
        </span>
        <span style={{ "--i": 8 }}>
          <img src={prabhupad}   alt="Prabhupad" />
        </span>
      </section>
    </div>

<div></div>
<br/>

      

    <section>
  <div className="container-fluid" style={{ backgroundColor: "black", width: "100%", padding: "20px 0" }}>
    <div className="row justify-content-center">
      <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
        <img src={headerlogo} className="footer-logo" alt="footer-logo" />
      </div>
      <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4 order-sm-1">
        <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
        <p className="mb-0 footer-para">
          Copyright - 2024 All Right Reserved
        </p>
      </div>
      <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
        <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
        <p className="mb-0 footer-para">
          Old Kattal Mandi, Abids, Hyderabad, Telangana 500001
        </p>
      </div>
    </div>
  </div>
</section>

    <Modal
      show={modalShow}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Processing Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="loading-spinner-container">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="70"
            visible={true}
          />
        </div>

        <p style={{ marginTop: "25%", color: "blue" }}>
          Please Wait. Your payment is currently being processed.
          <br /> Do not refresh or close your browser.
        </p>
      </Modal.Body>
    </Modal>

    <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <p style={{ marginTop: "5%", color: "green", fontSize: "18px" }}>
            Thanks For The Payment
          </p>
        </div>
      </Modal.Body>
    </Modal>
  
</div>
</div>
);
};
export default Annadan;

