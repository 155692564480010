import React, { useEffect, useState, useRef, Alert } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import "./gitadan.css";
import PhoneInput from "react-phone-input-2";
import { Row, Col, Card, Button } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import kmBanner from "././assets/gallery/kartik-mahotsav.jpg";
import krishnachanting from "./assets/gallery/janamshtami/chanting.mp3";
import audioOn from "./assets/gallery/janamshtami/audio-on.png";
import audioOff from "././assets/gallery/janamshtami/audio-off.png";

import pic1 from "./assets/img/download (1).jpg"
import pic2 from "./assets/img/download (2).jpg"
import pic3 from "./assets/img/download (3).jpg"
import pic4 from "./assets/img/download (4).jpg"
import pic5 from "./assets/img/download (5).jpg"
import pic6 from "./assets/img/download (6).jpg"
import pic7 from "./assets/img/download (7).jpg"
import pic8 from "./assets/img/download (8).jpg"
import pic9 from "./assets/img/download.jpg"
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "./interceptor";
import urls from "./endpoint";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
const GitaDaan = () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const options = [
    { value: null, label: "Please Select Gita Daan" },
   
    { value: "4001", label: "Gita Daan -16 Books" },
    { value: "8001", label: "Gita Daan -32 Books" },
    { value: "13501", label: "Gita Daan -54 Books" },
    { value: "27001", label: "Gita Daan -108 Books" },
    { value: "3201", label: "Pocket Gita -16 Books" },
    { value: "6401", label: "Pocket Gita -32 Books" },
    { value: "10801", label: "Pocket Gita -54 Books" },
    { value: "21601", label: "Pocket Gita -108 Books" },
    { value: "", label: "Gita Daan Any Other Amount" },
  ];
  const [selectedOptionForDonation, setSelectedOptionForDonation] = useState(
    options[0]
  );
  const [donorData, setDonorData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    address: "",
    dob: null,
    amount: "",
  });
  const [bookImage, setBookImage] = useState({
    image:
      "http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png",
    height: "450px",
    width: "100%",
  });
  const [devoteeData, setDevoteeData] = useState({});
  const audioRef = useRef(new Audio(krishnachanting));
  const { vid } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);
  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);
  const handleBookImage = (image, height, width) => {
    setBookImage(() => ({ image: image, height: height, width: width }));
  };
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
      audio.play();
    };

    const body = document.body;
    body.style.backgroundColor = "white";
  
    

    audio.addEventListener("ended", handleEnded, false);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      body.style.backgroundColor = ""; 
    };
  }, []);

  const playPause = () => {
    const audio = audioRef.current;
    const wasPlaying = isAudioPlaying;

    setIsAudioPlaying(!wasPlaying);

    if (wasPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  };
  const handleChangeSelect = (option) => {
    console.log(option, "option from handleselect");
    setSelectedOptionForDonation(option);
  };
  const handledonorData = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setDonorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDonateNow = () => {
    const newErrors = {};
    let hasError = false;

    if (!donorData.name) {
      newErrors.name = "Donor Name is required";
      hasError = true;
    }

    if (!donorData.email) {
      newErrors.email = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.email)) {
      newErrors.email = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobile_number) {
      newErrors.mobile_number = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (!selectedOptionForDonation.value && donorData.amount === '') {
      newErrors.gitadaan = "Please select atleast one Gita Daan";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    console.log("hi from donate now");
    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        console.log(res, "from 196");
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();

        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedOptionForDonation.value) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedOptionForDonation.label,
              image: { iskconabidslogo },
              order_id: res.data.data.order_id,
              handler: function (response) {
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.name, //your customer's name
                email: donorData.email,
                contact: donorData.mobile_number, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.name,
      devotee_mobile_no: donorData.mobile_number,
      devotee_email_id: donorData.email,
      devotee_dob: donorData.dob,
      devotee_address: donorData.address,
      
      sevas: [
        {
          name: selectedOptionForDonation.label,
          amount: selectedOptionForDonation.label === "Gita Daan Any Other Amount" 
            ? donorData.amount 
            : selectedOptionForDonation.value,
        },
      ],

      vid: vid,
      festival_name: selectedOptionForDonation.label,
    }));
  }, [donorData, selectedOptionForDonation]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    try {
      console.log(devoteeData, "data");
      const response = await axios.post(urls.create_donation, devoteeData);
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      alert("Error", "Failed to create donation. Please try again.");
      throw error;
    }
    // const response = await axios.post(urls.create_donation, devoteeData);

    // return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    if (paymentStatus === "paid") {
      handleShow();
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          handleClose();
          handleShowSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div>
      <div
        className="video-background"
        style={{ backgroundColor: "white" }}
      ></div>
      <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-9 col-5 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-3 col-7  text-left ">
              <button
                className="btn btn-h-donate "
                onClick={playPause}
                style={{ borderRadius: "20px" }}
              >
                {isAudioPlaying ? (
                  <>
                    <marquee width="60%" direction="right" height="18px">
                      Hare Krishna Mantra
                    </marquee>
                    <img
                      src={audioOn}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Pause"
                    />
                  </>
                ) : (
                  <>
                    Hare Krishna Mantra
                    <img
                      src={audioOff}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Play"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className="banner-image">
                <img
                  src={
                    "http://iskconhyderabad.com/wp-content/uploads/kumbh-banner.jpg"
                  }
                  alt="Banner"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>

    <div id="container">
      <div className="product-details">
        <h1>Gyaan Daan</h1>
        <p className="quote">
          ~ Let's donate Bhagavatam and spread the Gyaan ~
        </p>
        <p className="information">
          "Gyaan Daan is a Maha Daan. The benefits are immense both for the
          donor and the recipient. It spreads the knowledge of the absolute
          truth and affords a person the chance to perfect their life. There is
          no flip side to donating the Gita."
        </p>
        <p className="information">
          Gita is considered an excellent source of spiritual knowledge and
          thought for humanity.
        </p>
        <p className="information">
          The Gita teaches us the right way to lead life. Spread the knowledge
          by donating copies of Bhagavad Gita and help people find spiritual
          solutions to their material problems.
        </p>
      </div>

      <div className="product-image">
        <img
          // src="http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png"
          src="https://iskconhyderabad.com/wp-content/uploads/IMG-20211229-WA0004.jpg"
          alt="Bhagavad Gita"
        />
      </div>
    </div>
    <div class="gita-container">
  <h1 style={{color:"Black"}}>Highlights of Donating the Bhagavad Gita</h1>

  <div class="gita-card">
    <div class="gita-box">
      <div class="gita-content">
        <h2>01</h2>
        <h3>Spiritual Upliftment</h3>
        <p>Your donation helps reduce sinful habits and promotes a life of righteousness</p>
      </div>
    </div>
  </div>

  <div class="gita-card">
    <div class="gita-box">
      <div class="gita-content">
        <h2>02</h2>
        <h3>Transformative Impact</h3>
        <p> By sharing the timeless wisdom of the Gita, you empower others to change their lives for the better</p>
      </div>
    </div>
  </div>

  <div class="gita-card">
    <div class="gita-box">
      <div class="gita-content">
        <h2>03</h2>
        <h3>Lasting Legacy</h3>
        <p> Unlike temporary gifts, the knowledge in the Bhagavad Gita transforms lives and has a long-term positive effect</p>
      </div>
    </div>
  </div>
</div>

        
      </section>

      <div
        style={{
          height: "93%",
          width: "93%",

          margin: "5%",
        }}
      >

<section className="signup-container" style={{ marginTop: "10%" }}>
      {/* Left Section */}
      <div className="left-container">
        <h1>
          <i className="fas fa-paw"></i> Support GitaDaandan Seva
        </h1>
        <div></div>
        <div className="puppy">
          <img
            src="http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png"
            alt="gita Illustration"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div></div>
        <br />
      </div>

      {/* Right Section */}
      <div className="right-container">
        <div>
          <div className="set">
            <div className="pets-name">
              <label htmlFor="seva-amount">Donation For</label>
              <div className="amount-button-container">
                <Select
                  value={selectedOptionForDonation}
                  onChange={handleChangeSelect}
                  options={options}
                />
                {errors.gitadaan && (
                  <p className="error" style={{ color: "red" }}>
                    {errors.gitadaan}
                  </p>
                )}
              </div>
            </div>
            <div className="pets-name">
              <label htmlFor="other-amount">Amount</label>
              <input
    type="text"
    id="other-amount"
    name="amount"
                value={
                  selectedOptionForDonation.label !== "Gita Daan Any Other Amount"
                    ? selectedOptionForDonation.value
                    : donorData.amount
                }
                onChange={handledonorData}
                disabled={
                  selectedOptionForDonation?.label !== "Gita Daan Any Other Amount"
                }
  />
            </div>
          </div>

          <div className="set">
            <div className="pets-name">
              <label htmlFor="donorName">Enter Name</label>
              <input
                type="text"
                id="donorName"
                name="name"
                value={donorData.name}
                placeholder="Enter Donor Name"
                onChange={handledonorData}
              />
               <div>
                        {errors.name && (
                          <p className="error " style={{ color: "red" }}>
                            {errors.name}
                          </p>
                        )}
                      </div>
            </div>
            <div className="pets-name">
              <label htmlFor="emailID">Enter Email</label>
              <input
                type="email"
                id="emailID"
                name="email"
                value={donorData.email}
                placeholder="Enter Email ID"
                onChange={handledonorData}
              />
               {errors.email && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.email}
                        </p>
                      )}
            </div>
          </div>

          <div className="set">
            <div className="pets-name">
              <label htmlFor="phone">Enter Mobile Number</label>
              <PhoneInput
                country={"in"}
                value={donorData.mobile_number}
                onChange={(value) => setDonorData({ ...donorData, mobile_number: value })}
                placeholder="Enter Mobile Number"
                inputProps={{
                  name: "mobile_number",
                  required: true,
                  autoFocus: true,
                }}

              />
               {errors.mobile_number && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.mobile_number}
                        </p>
                      )}
            </div>
            <div className="pets-name">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={donorData.dob}
                onChange={handledonorData}
              />
            </div>
          </div>

          <div className="set">
            <div className="pets-name">
              <label htmlFor="address">Enter Address</label>
              <textarea
                id="address"
                name="address"
                value={donorData.address}
                placeholder="Enter Address"
                onChange={handledonorData}
              />
            </div>
          </div>

          {/* Terms and Conditions */}
          <footer>
            <p>
              By continuing, you agree to our{" "}
              <a
                href="https://iskconhyderabad.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </p>
            <div className="btn-group">
              <button
                className="btn"
                onClick={handleDonateNow}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#4CAF50",
                  color: "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Donate Now Rs-
                {selectedOptionForDonation?.label !== "Gita Daan Any Other Amount"
                  ? selectedOptionForDonation.value
                  : donorData.amount}
              </button>
            </div>
          </footer>
        </div>
      </div>
    </section>


       
    <h2 className="gyaanDaan mt-5">OR</h2>
<div style={{  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  flexWrap: 'wrap', 
  marginBottom: 30 }}>
  <Card className="card">
    <Row>
      <Col md={6} sm={12}>
        <h5 className="scanText">SCAN & PAY WITH ANY UPI APP</h5>
        <img
          src="http://iskconhyderabad.com/wp-content/uploads/ISKCON-HYD-QR.png"
          alt="UPI QR Code"
          className="qrImage"
        />
      </Col>
      <Col md={6} sm={12}>
        <h5 className="scanText">DIRECT BANK NEFT/RTGS/IMPS</h5>
        <div className="bankDetailsContainer">
          <h3 className="bankDetailsText">ISKCON Account Details:</h3>
          <h3 className="bankDetailsText">Name of account: International Society for Krishna Consciousness</h3>
          <h3 className="bankDetailsText">A/C # 041001005498</h3>
          <h3 className="bankDetailsText">IFSC Code: ICIC0000410</h3>
          <h3 className="bankDetailsText">ICICI Bank Abids Branch.</h3>
        </div>
      </Col>
    </Row>
    <h4 className="receiptText mb-5">
      For Receipts and other Details: +917559441515 Email: iyshyderabad@gmail.com
    </h4>
  </Card>
</div>

    <h1 style={{color:"Black",textAlign: "center"}}>Gallery</h1> 
   
    <div className="galleryi">
      <img src={pic8} alt="gita media" />
      <img src={pic2} alt="gita media" />
      <img src={pic3} alt="gita media" />
      <img src={pic4} alt="gita media" />
      <img src={pic9} alt="gita media" />
      <img src={pic6} alt="gita media" />
      <img src={pic7} alt="gita media" />
      <img src={pic1} alt="gita media" />
      <img src={pic5} alt="gita media" />

    </div>
    
        <Modal
          show={modalShow}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Processing Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-spinner-container">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={true}
                style={{ alignItems: "center", justifyContent: "center" }}
              />
            </div>

            <p style={{ marginTop: "25%", color: "blue" }}>
              Please Wait. Your payment is currently being processed.
              <br /> Do not refresh or close your browser.
            </p>
          </Modal.Body>
        </Modal>

        <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment Sucessful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p style={{ marginTop: "5%", color: "green", fontSize: "18px" }}>
                Thanks For The Payment
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
export default GitaDaan;
